import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { useEffect } from 'react'
// import { hydrateRoot } from 'react-dom/client'
import { hydrate } from 'react-dom'

Sentry.init({
  dsn: 'https://115ce8810d0741d8b18f25e2eccc34e5@o140591.ingest.us.sentry.io/1307695',
  tracesSampleRate: 0.001,
  sampleRate: 0.025,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    })
  ],

  allowUrls: [
    /https?:\/\/([a-z0-9-]+\.)?sporza\.be/,
    /https?:\/\/([a-z0-9-]+\.)?vrt\.be/
  ]
})

// React 18's hydrateRoot is buggy
// Currently workaround: https://github.com/remix-run/remix/issues/4175#issuecomment-1244930213
// Other resources
// - https://github.com/remix-run/remix/issues/2947
// - https://github.com/remix-run/remix/issues/2570
// - https://github.com/facebook/react/issues/22833
// TODO: Check if we can upgrade this to react 18's hydrateRoot
hydrate(<RemixBrowser />, document)

// Trying out react 18.2 way of hydrating to see if we still have any issues
// hydrateRoot(document, <RemixBrowser />)
